import cx from 'classnames';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';

import DownloadIcon from '../../../assets/icons/cloud-download.svg';
import PdfIcon from '../../../assets/icons/pdf.svg';
import ZipIcon from '../../../assets/icons/zip.svg';
import styles from './gallery.module.scss';

export const MediaPackButton = ({ name, url, type }) => {
	const intl = useIntl();
	const IconComponent = type === 'zip' ? ZipIcon : PdfIcon;
	const TextContent =
		type === 'zip' ? (
			<>
				<span className={styles.displayOnDesktop}>{name}</span>
				<span className={styles.displayOnMobile}>{intl.formatMessage({ id: 'media_pack' })}</span>
			</>
		) : (
			<span>{name}</span>
		);

	return (
		<a href={url} className={styles.mediaPackButton} target="_blank" rel="noreferrer">
			{TextContent}
			<div className={styles.iconWrapper}>
				<IconComponent className={cx(styles.downloadButtonIcon, styles.zipIcon)} />
				<DownloadIcon className={cx(styles.downloadButtonIcon, styles.downloadIcon)} />
			</div>
		</a>
	);
};

MediaPackButton.propTypes = {
	name: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['pdf', 'zip']),
};

MediaPackButton.defaultProps = {
	type: 'zip',
};
