import cx from 'classnames';
import { Link, useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { GALLERY_NAV, ROUTES } from '../../../utils';
import styles from './gallery.module.scss';

export const GalleryNav = ({ sectionName, active, isGallerySubpage }) => {
	const intl = useIntl();
	const renderNavItem = (navItem, index) => {
		const shouldBeActive = navItem.name === active;
		return (
			<li key={`gallery-nav-item-${index}`}>
				<Link
					className={cx(shouldBeActive && styles.activeLink)}
					activeClassName={styles.activeLink}
					to={navItem.route}>
					{intl.formatMessage({ id: `nav.${navItem.translationKey}` })}
				</Link>
			</li>
		);
	};
	return (
		<div className={styles.galleryNav}>
			<h1>{intl.formatMessage({ id: 'nav.media_gallery' })}</h1>
			{sectionName && <p>{sectionName}</p>}
			<nav>
				<ul>
					{GALLERY_NAV.map(renderNavItem)}
					{isGallerySubpage && (
						<li className={styles.backButton}>
							<Link to={ROUTES.newsroom.mediaGallery}>{intl.formatMessage({ id: 'close' })}</Link>
						</li>
					)}
				</ul>
			</nav>
		</div>
	);
};

GalleryNav.propTypes = {
	sectionName: PropTypes.string,
	active: PropTypes.string,
	isGallerySubpage: PropTypes.bool,
};
