import cx from 'classnames';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import DownloadIcon from '../../../assets/icons/cloud-download.svg';
import JpgIcon from '../../../assets/icons/jpg.svg';
import { GALLERY_TYPES } from '../../../utils';
import styles from './gallery.module.scss';

const getItemClassName = type => {
	let className;
	switch (type) {
		case GALLERY_TYPES.management:
			className = styles.managementItem;
			break;
		case GALLERY_TYPES.brand:
			className = styles.brandItem;
			break;
		default:
			className = styles.item;
			break;
	}
	return className;
};

export const GalleryList = ({ type, listData }) => {
	const renderItem = (item, index) => {
		const { name, description, image } = item;
		const { fluid, url, fileName } = image;
		return (
			<li key={`gallery-list-item-${index}`} className={getItemClassName(type)}>
				<div className={styles.imgWrapper}>
					<Img fluid={fluid} alt={name} />
				</div>
				<div className={styles.itemBottomSection}>
					<div className={styles.textWrapper}>
						<h3>{name}</h3>
						{description && <p>{description}</p>}
					</div>
					<a className={styles.downloadButton} href={url} download={fileName} target="_blank" rel="noreferrer">
						<JpgIcon className={cx(styles.downloadButtonIcon, styles.jpgIcon)} />
						<DownloadIcon className={cx(styles.downloadButtonIcon, styles.downloadIcon)} />
					</a>
				</div>
			</li>
		);
	};
	return <ul className={styles.galleryList}>{listData.map(renderItem)}</ul>;
};

GalleryList.propTypes = {
	type: PropTypes.string.isRequired,
	listData: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			description: PropTypes.string,
			image: PropTypes.shape({
				fluid: PropTypes.object.isRequired,
				url: PropTypes.string.isRequired,
				fileName: PropTypes.string.isRequired,
			}).isRequired,
		})
	),
};
