import cx from 'classnames';
import { Link, useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';

import DownloadIcon from '../../../assets/icons/cloud-download.svg';
import PdfIcon from '../../../assets/icons/pdf.svg';
import ZipIcon from '../../../assets/icons/zip.svg';
import { ROUTES } from '../../../utils';
import { ContentLayout } from '../../ContentLayout';
import styles from './gallery.module.scss';

export const GalleryMain = ({ listData }) => {
	const intl = useIntl();
	const renderSection = (section, index, desktop) => {
		const { name, path, packageUrl, pdfUrl, backgroundSrc } = section;
		const fullPath = `${ROUTES.newsroom.mediaGallery}/${path}`;
		const iconUrl = packageUrl || pdfUrl;
		const IconComponent = packageUrl ? ZipIcon : PdfIcon;
		return (
			<li key={`gallery-section-${index}`}>
				<Link to={fullPath} className={styles.link}>
					{backgroundSrc && <div className={styles.background} style={{ backgroundImage: `url(${backgroundSrc})` }} />}
					{desktop ? (
						<div className={styles.desktopItemContent}>
							<div className={styles.box}>
								{iconUrl && (
									<div className={styles.buttonWrapper}>
										<a
											href={iconUrl}
											className={styles.packButton}
											onClick={e => e.stopPropagation()}
											target="_blank"
											rel="noreferrer">
											<div className={styles.iconWrapper}>
												<IconComponent className={cx(styles.downloadButtonIcon, styles.zipIcon)} />
												<DownloadIcon className={cx(styles.downloadButtonIcon, styles.downloadIcon)} />
											</div>
										</a>
									</div>
								)}
								<h3>{name}</h3>
								<span>{intl.formatMessage({ id: 'view' })}</span>
							</div>
						</div>
					) : (
						<ContentLayout className={styles.mobileItemContent}>
							<div className={styles.box}>
								<span>{section.name}</span>
							</div>
						</ContentLayout>
					)}
				</Link>
			</li>
		);
	};
	const renderMobileSection = (section, index) => renderSection(section, index, false);
	const renderDesktopSection = (section, index) => renderSection(section, index, true);
	return (
		<>
			<div className={styles.displayOnMobile}>
				<ul className={styles.galleryMainList}>{listData.map(renderMobileSection)}</ul>
			</div>
			<ContentLayout className={styles.displayOnDesktop}>
				<ul className={styles.galleryMainList}>{listData.map(renderDesktopSection)}</ul>
			</ContentLayout>
		</>
	);
};

GalleryMain.propTypes = {
	listData: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
			packageUrl: PropTypes.string,
			pdfUrl: PropTypes.string,
			backgroundSrc: PropTypes.string,
		})
	),
};
